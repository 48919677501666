<template>
  <b-form
    autocomplete="off"
    @submit.prevent="searchVendorData"
  >
    <b-row class="mb-2">
      <b-col cols="4">
        <b-form-group>
          <v-select
            v-model="filter.params.vendor_type"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="vendor_type_name"
            :reduce="op => op.vendor_type_id"
            :placeholder="t('Vendor Type')"
            :options="vendorOptions"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-name"
              v-model="filter.params.name"
              type="text"
              autocomplete="off"
              :placeholder="t('Name')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-vendor_code"
              v-model="filter.params.vendor_code"
              type="text"
              autocomplete="off"
              :placeholder="t('Vendor Code')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-status"
              v-model="filter.params.status"
              type="text"
              autocomplete="off"
              placeholder="Status"
            />
          </b-input-group>
        </b-form-group>
      </b-col> -->

      <b-col cols="4">
        <b-form-group>
          <v-select
            v-model="filter.params.period"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="op => op.value"
            :placeholder="t('Period')"
            :options="periodOptions[$i18n.locale]"
            @input="onChangePeriod"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="filter.params.start_date"
              :placeholder="t('Select Start Date')"
              class="form-control"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="filter.params.end_date"
              class="form-control"
              :placeholder="t('Select End Date')"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="success mx-1"
          type="submit"
        >
          {{ t('Search') }}
        </b-button>
        <b-button
          variant="danger mx-1"
          @click="resetFilter"
        >
          {{ t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import vSelect from 'vue-select'
import {
  BRow, BCol, BInputGroup, BFormGroup, BButton, BForm, BFormInput,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { useUtils } from '@core/libs/i18n'
// eslint-disable-next-line import/no-duplicates
import { periodOptions } from '@/utils/common'
// eslint-disable-next-line import/no-duplicates
import { getDateByPeriod } from '@/utils/common'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BButton,
    // BCollapse,
  },
  props: ['filter'],
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      // eslint-disable-next-line new-cap
      periodOptions,
      isDateDisable: true,
      vendorOptions: [{
        vendor_type_name: 'Company',
        vendor_type_id: 1,
      }, {
        vendor_type_name: 'Individual',
        vendor_type_id: 2,
      }],
    }
  },
  mounted() {
    let today = new Date()
    const dd = String(today.getDate()).padStart(2, '0')
    const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
    const yyyy = today.getFullYear()
    today = `${yyyy}-${mm}-${dd}`
    this.filter.params.start_date = today
    this.filter.params.end_date = today
    this.searchVendorData()
  },
  methods: {
    onChangePeriod(val) {
      const dates = getDateByPeriod(val)
      this.filter.params.start_date = dates['0']
      this.filter.params.end_date = dates['1']
      this.isDateDisable = dates['2']
    },
    searchVendorData() {
      this.$emit('filterVendor', this.filter)
    },
    resetFilter() {
      this.filter = {
        params: {
          vendor_type: '',
          name: '',
          vendor_code: '',
          status: '',
          start_date: '',
          end_date: '',
        },
        sortBy: 'id',
        sortOrder: 'desc',
        size: '',
        pageNumber: '',
      }
      this.searchVendorData()
    },
  },

}
</script>

<style>
</style>
