<template>
  <div>
    <div class="d-flex justify-content-between mb-1">

      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>

      <b-button
        variant="primary"
        @click="redirectToAddVendorwithCSV"
      >

        <feather-icon icon="PlusIcon" />
        Import Vendor
      </b-button>
    </div>

    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <vendor-filter
        :filter="filter"
        @filterVendor="recieveData"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        striped
        hover
        responsive="true"
        :items="items.data"
        :fields="vendorFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(id)="data">
          <span class="">{{ data.item.id }}</span>
        </template>
        <template #cell(name)="data">
          <span class="">{{ data.item.name }}</span>
        </template>
        <template #cell(vendor_code)="data">
          <span
            class=""
            @click="$router.push(`/vendor/${data.item.vendor_id}`)"
          ><a href="javascript:void(0)">{{ data.item.vendor_code }}</a></span>
        </template>
        <template #cell(vendor_type_name)="data">
          <span class="">{{ data.item.vendor_type_name }}</span>
        </template>
        <template #cell(customer_type)="data">
          <span class="">{{ data.item.customer_type }}</span>
        </template>
        <template #cell(landline)="data">
          <span class="">{{ data.item.landline }}</span>
        </template>
        <template #cell(website)="data">
          <a
            v-if="data.item.website"
            :href="data.item.website"
            class=""
            target="_blank"
          >
            <b-badge
              v-b-tooltip.hover.focus.v-primary
              variant="primary"
              :title="data.item.website"
            >
              URL
            </b-badge>
          </a>
        </template>
        <template #cell(created_by_name)="data">
          <span class="">{{ data.item.created_by_name }}</span>
        </template>
        <template #cell(created_date)="data">
          <span class="">{{ data.item.created_date }}
          </span>
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            No Record Found
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">

        <b-col
          lg="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            {{ t('Total Entries') + " " + items.total }}
          </div>

          <b-form-group class="mb-0 align-self-center">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="filter.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getVendorList"
            />
          </b-form-group>

        </b-col>
        <b-col lg="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>

    <vue-context
      ref="vendorContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
        <b-link
          v-if="data.text == 'View Vendor' && checkPermission(permissions.LEAD_MORE_DETAILS)"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-else-if="data.text != 'View Vendor'"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <add-vendor-modal />
  </div>
</template>

<script>
import axios from 'axios'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BCard, BLink, BBadge, BCollapse, VBToggle, BButton, BRow, BCol, VBTooltip, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import VueContext from 'vue-context'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'
import constants from '@/constants'
import VendorFilter from './VendorFilter.vue'
import AddVendorModal from './AddVendorModal.vue'
import checkPermissions from '@/utils/checkPermissons'
import { showLoader, hideLoader, tableFilter } from '@/utils/common'
import permissions from '@/permissions'

export default {
  name: 'VendorList',
  components: {
    BLink,
    BCard,
    BRow,
    BTable,
    VueContext,
    BBadge,
    BCollapse,
    AddVendorModal,
    BButton,
    VendorFilter,
    BCol,
    BFormGroup,
    BFormSelect,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  setup() {
    const { t } = useUtils()
    return { t, permissions }
  },
  data() {
    return {
      tableFilter,
      constants,
      items: [],
      valuesContext: {},
      filter: {
        params: {
          vendor_type: '',
          name: '',
          vendor_code: '',
          status: '',
          start_date: '',
          end_date: '',
        },
        sortBy: 'id',
        sortOrder: 'desc',
        size: 10,
        pageNumber: '',
      },
    }
  },
  computed: {
    menuData() {
      return [
        { id: 1, icon: 'EditIcon', text: this.$i18n.t('Edit Vendor') },
        { id: 2, icon: 'EyeIcon', text: this.$i18n.t('View Details') },
      ]
    },
    vendorFields() {
      return [
        { key: 'id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'vendor_code', label: this.$i18n.t('Vendor Code'), sortable: true },
        { key: 'name', label: this.$i18n.t('Name'), sortable: true },
        { key: 'vendor_type_name', label: this.$i18n.t('Vendor Type'), sortable: true },
        { key: 'customer_type', label: this.$i18n.t('Customer Type'), sortable: true },
        { key: 'created_by_name', label: this.$i18n.t('Created By'), sortable: true },
        { key: 'created_date', label: this.$i18n.t('Created Date'), sortable: true },
        { key: 'landline', label: this.$i18n.t('Landline'), sortable: true },
        { key: 'website', label: this.$i18n.t('Website'), sortable: true },
      ]
    },
  },
  mounted() {
    this.getVendorList()
  },
  created() {
  },
  methods: {
    redirectToAddVendorwithCSV() {
      this.$bvModal.show('addVendorCSV')
    },
    checkPermission(val) {
      return checkPermissions(val)
    },
    sortChanged(val) {
      this.filter.sortBy = val.sortBy
      this.filter.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getVendorList()
    },
    async getVendorList() {
      showLoader()
      await store.dispatch('vendorStore/getVendorList', this.filter).then(response => {
        this.items = response.data.data
      })
      hideLoader()
    },
    redirectToCreate() {
      this.$router.push('/admin/create-users')
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.valuesContext = item
      this.$refs.vendorContextMenu.open(event, item)
    },
    optionClicked(id) {
      // eslint-disable-next-line eqeqeq
      if (id == 2) {
        this.$router.push(`/vendor/${this.valuesContext.vendor_id}`)
      // eslint-disable-next-line eqeqeq
      } else if (id == 1) {
        this.$router.push(`/vendor-edit/${this.valuesContext.vendor_id}`)
      }
    },
    recieveData(val) {
      this.filter = val
      this.getVendorList()
    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`${constants.COMPANY_API_PREFIX}/vendor/list-vendor?page=${page}`, this.filter, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
          hideLoader()
        })
      hideLoader()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
