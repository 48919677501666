<template>
  <b-modal
    id="addVendorCSV"
    title="Import Vendor"
    hide-footer
  >
    <validation-observer
      ref="addVendor"
    >
      <b-form @submit.prevent="addVendorCSV">
        <b-form-group
          label="Vendor File"
          label-for="vendorFile"
        >
          <validation-provider
            #default="{ errors }"
            name="Vendor File"
            vid="vendorFile"
            rules="required"
          >
            <b-form-file
              id="vendorFile"
              v-model="form.file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              :placeholder="t('Upload CSV')"
              :browse-text="t('Browse')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <div class="text-right">
          <b-button
            type="submit"
            variant="success"
          >
            Import Vendor
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import apis from '@/apis'
import store from '@/store'
import constants from '@/constants'
import {
  failedToast, hideLoader, showLoader, successToast,
} from '@/utils/common'

export default {
  name: 'AddVendorCSV',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      form: {
        file: [],
      },
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  methods: {
    addVendorCSV() {
      this.$refs.addVendor.validate().then(success => {
        if (success) {
          this.uploadCSV()
        }
      })
    },
    async uploadCSV() {
      showLoader()
      // eslint-disable-next-line prefer-const
      let formData = new FormData()
      Object.entries(this.form).forEach(
        ([key, value]) => { if (key !== 'profile_photo') formData.append(key, value) },
      )
      await store.dispatch('vendorStore/importVendor', { url: apis.IMPORT_VENDORS, data: formData }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          hideLoader()
          successToast(response.data.msg)
          this.$bvModal.hide('addVendor')
        }
      }).catch(e => {
        hideLoader()
        failedToast(e.msg)
      })
    },
  },
}
</script>

<style>

</style>
